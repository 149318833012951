import React, {Component} from "react";
import {Row, Col, Image, Button} from "react-bootstrap";

export default class Feature extends Component {
    render() {
        // Don't invert on mobile.
        if(this.props.invert && window.innerWidth > 991) {
            return (
                <div style={{marginBottom: "50px"}}>
                    <Row>
                        <Col lg={3}>
                            <h1>{this.props.header}</h1>
                            <p>{this.props.children}</p>
                            {/*<Button variant="primary" style={{width: "50%"}}>{this.props.buttonText}</Button>*/}
                        </Col>
                        <Col md={3}>
                            <Image src={this.props.image} rounded />
                        </Col>
                    </Row>
                    <br />
                </div>
            );
        }
        return (
            <div style={{marginBottom: "50px"}}>
                <Row>
                    <Col lg={3}>
                        <Image src={this.props.image} rounded />
                    </Col>
                    <Col>
                        <h1>{this.props.header}</h1>
                        <p>{this.props.children}</p>
                        {/*<Button variant="primary" style={{width: "50%"}}>{this.props.buttonText}</Button>*/}
                    </Col>
                </Row>
                <br />

            </div>
        );

    }
}