import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Page Imports
import Home from "./pages/Home";
import Tracking from "./pages/Tracking";

// Temporary placeholder pages
function RoadPatrol() {
    return <h2>Road Patrol</h2>;
}
function HOS() {
    return <h2>HOS</h2>;
}

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Header />
                    <Switch>
                        <Route path="/tracking">
                            <Tracking />
                        </Route>
                        <Route path="/roadpatrol">
                            <RoadPatrol />
                        </Route>
                        <Route path="/hos">
                            <HOS />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default App;
