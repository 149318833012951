import React, {Component} from "react";
import {Carousel, Image} from "react-bootstrap";


import CSE from "../img/carousel/CSE.jpg";
import interg from "../img/carousel/interg.jpg";
import dataAna from "../img/carousel/dataAna.jpg";
import RoadP from "../img/carousel/RoadP.jpg";
import AVL from "../img/carousel/AVL.jpg"

export default class HomepageCarousel extends Component {
    render() {
        return (
            <div>
                <Carousel style={{top: "-100px" }} className={"c-mobile"} indicators={false} pause={false}>
                    <Carousel.Item>
                        <Image
                            className={"c-mobile-image"}
                            style={{objectFit: "cover", height: "90vh", width: "100vw"}}
                            src={CSE}
                            alt="Custom Software Engineering"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className={"c-mobile-image"}
                            style={{objectFit: "cover", height: "90vh", width: "100vw"}}
                            src={interg}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className={"c-mobile-image"}
                            style={{objectFit: "cover", height: "90vh", width: "100vw"}}
                            src={dataAna}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className={"c-mobile-image"}
                            style={{objectFit: "cover", height: "90vh", width: "100vw"}}
                            src={RoadP}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className={"c-mobile-image"}
                            style={{objectFit: "cover", height: "90vh", width: "100vw"}}
                            src={AVL}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

        );
    }
}