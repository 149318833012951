import React from 'react';
import ReactDOM, {hydrate, render} from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<React.StrictMode><App /></React.StrictMode>, rootElement);
} else {
    render(<React.StrictMode><App /></React.StrictMode>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
