import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Navbar, Nav, Container, Image} from "react-bootstrap";

import logo from "../img/logo.png";

export default class Header extends Component {
    render() {
        return (
            <Navbar fixed="sticky" bg={"light"} expand="lg" style={{zIndex: 99}}>
                <Container>
                    <Navbar.Brand>
                        DM&T Services Ltd.
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="ml-auto">
                            <Nav.Link href="#contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        );
    }
}