import styled from 'styled-components'

// Pass in a FontAwesome Icon as a child to use
const ScrollArrow = styled.a`
    position: absolute;
    width: 48px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10%;
    animation-name: pointy-arrow;
    animation-duration: .5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    color: black;
    @keyframes pointy-arrow {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(10px);
        }
    }
`


export default ScrollArrow;