import React, {Component} from "react";
import {Container, Image} from "react-bootstrap";

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget fermentum dolor, ut euismod neque. Sed sed maximus augue, at imperdiet augue. Suspendisse nunc erat, suscipit non facilisis eu, blandit a lorem. Vestibulum ac ligula eu ligula iaculis cursus non at justo. Ut eget dui sem. Suspendisse potenti. Nulla nunc est, vehicula et felis sit amet, tristique fringilla nisi. Sed et pharetra neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum sit amet urna auctor, pretium purus sit amet, blandit mauris. Ut eget lectus quis quam viverra hendrerit. ";


export default class Tracking extends Component {
    render() {
        return (
            <Container>
                <h1>Tracking</h1>
                <p>{lorem}</p>
                <Image src={'https://via.placeholder.com/1500x350'} />
                <p>{lorem}</p>
                <p>{lorem}</p>
            </Container>
        );
    }
}