import React, {Component} from "react";
import {Card, Container, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";

export default class Footer extends Component {
    render() {
        return (
            <footer style={{marginTop: "100px"}}>
                <div style={{ width: '100%', backgroundColor: "black", color: "white" }}>
                    <Container>
                        <br />
                        <Row>
                            <Col className="text-start">
                                <p>
                                    <p>&copy; {new Date().getFullYear()} <a href={"#"}>DM&T Services Ltd</a></p>
                                    <p>All Rights Reserved</p>
                                </p>
                            </Col>
                            <Col className="text-end">
                                <p><FontAwesomeIcon icon={faPhone} /> (905) 731-0142</p>
                                <p><FontAwesomeIcon icon={faEnvelope} /> info@dmtserv.com</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        );
    }
}