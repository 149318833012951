import React, {Component} from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

import HomepageCarousel from "../components/HomepageCarousel";
import Feature from "../components/Feature";
import ScrollArrow from "../components/ScrollArrow";

// Images
import CusSoftIcon from "../img/featureIcons/CusSoftIcon.jpg";
import interIcon from "../img/featureIcons/interIcon.jpg";
import TrackIcon from "../img/featureIcons/TrackIcon.jpg";
import RoadComIcon from "../img/featureIcons/RoadComIcon.jpg";

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget fermentum dolor, ut euismod neque. Sed sed maximus augue, at imperdiet augue. Suspendisse nunc erat, suscipit non facilisis eu, blandit a lorem. Vestibulum ac ligula eu ligula iaculis cursus non at justo. Ut eget dui sem. Suspendisse potenti. Nulla nunc est, vehicula et felis sit amet, tristique fringilla nisi. Sed et pharetra neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum sit amet urna auctor, pretium purus sit amet, blandit mauris. Ut eget lectus quis quam viverra hendrerit. ";


export default class Home extends Component {
    render() {
        return (
            <div className="App">
                <HomepageCarousel />
                {/*<ScrollArrow href={'#features'}><FontAwesomeIcon icon={faChevronDown} size="3x" /></ScrollArrow>*/}
                <Container id={"features"}>
                    <Feature image={CusSoftIcon} header={"Custom Software"} buttonText={"Learn More"} buttonLink={"#"}>
                        <p>Our ability to provide custom software to customers delivers benefits that meet the exact requirements of the organisation. Application software that DM&T provides also benefits from our ability to customize those applications as well.</p>
                        <p>Creating software that meets customer expectations requires good collaboration and communication between users, developers, and any third-party organizations.</p>
                        <p>Developing a working group sharing knowledge, getting buy in from operations will deliver quality software that will add value to the organisation.</p>
                    </Feature>
                    <Feature image={interIcon} header={"Integration"} buttonText={"Learn More"} buttonLink={"#"}>
                        <p>Connecting systems to each other delivers many advantages for organisations that have existing applications.  Users can have the ability to communicate with all systems easily, data is shared between the applications, allows for incremental growth and saves cost.</p>
                        <p>All applications at DM&T supports strong integration.  Whether it is a simple file / database integration or using an API.  Our APIs work with most third-party applications.  DM&T provides integration support to IT professional and other software application companies.</p>
                    </Feature>
                    <Feature image={TrackIcon} header={"Tracking"} buttonText={"Learn More"} buttonLink={"#"}>
                        <p>We use GPS hardware fabricated by us or from a third-party vendor. This will tell you where the vehicle is located but that is just one data point that the solution delivers.  Our devices have as many datapoints as required connection points that deliver engine data (ECM), status of a myriad number of components on the vehicle and driver information.</p>
                        <p>Communication with the vehicle is supported by cell coverage, Wi-Fi and satellite.</p>
                        <p>The Winter Operations Report (WOR) was developed (format based on MTO reporting requirements) to give timely reporting during the active season.</p>
                    </Feature>
                    <Feature image={RoadComIcon} header={"Road Compliance"} buttonText={"Learn More"} buttonLink={"#"}>
                        <p>Our ROADA solution meets the Ministry’s Minimum Maintenance Requirements (MMR O.Reg 239/02) and then some.  The design allows for customization by individual customer to meet local requirements. </p>
                        <p>Tight integration to controllers in the vehicle provides for very accurate data flowing back to the database in real-time.  This ability allows customers to be confident that the data collected via our solution meets or exceeds the MMR.</p>
                    </Feature>
                </Container>

                <Container className={"text-center"}>
                    <hr />
                    <h3>Ask about projects completed in the private / public sectors</h3>
                    <br />
                    <Row className={"text-primary row-cols-1 row-cols-lg-3"}>
                        <Col><b>Weigh Scale Management</b></Col>
                        <Col><b>Courier / Logistics Services</b></Col>
                        <Col><b>Waste Management</b></Col>
                    </Row>
                    <br />
                    <Row className={"text-primary row-cols-1 row-cols-lg-3"}>
                        <Col><b>Parking & Access Management</b></Col>
                        <Col><b>Plant Automation</b></Col>
                        <Col><b>Asset Tracking</b></Col>
                    </Row>
                </Container>
                <br />
                <Container id={"contact"}>
                    <hr />
                    <br />
                    <h1>Contact Us</h1>
                    <Row>
                        <Col>
                            <Row>&nbsp;&nbsp;DM&T Services Ltd </Row>
                            <Row>&nbsp;&nbsp;75 East Beaver Creek Road, Unit 6</Row>   
                            <Row>&nbsp;&nbsp;Richmond Hill, Ontario L4B 1B8</Row>   
                            <Row>&nbsp;&nbsp;905-731-0142</Row>   
                            <Row>&nbsp;&nbsp;info@dmtserv.com</Row>                                         
                        </Col>
                        <Col className={"text-end"}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d719.2481510309203!2d-79.38020917074033!3d43.85598196574335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d4cd6aa0fa45%3A0xbb12ea6b1267d167!2sDM%26T%20Services%20LTd.!5e0!3m2!1sen!2sca!4v1629818200769!5m2!1sen!2sca"
                                width="100%" height="300" style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>
                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}